/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  AbstractServerInteractionService,
  combineHeadersOptions,
  commonErrorHandlingDisabled,
  globalLoadingDisabled,
} from '../abstract/abstract.server.interaction.service';

import {
  EmployeeStatusEnum,
  IContactConvertationData,
  IRecruitingLocation,
  ITeamResponse
} from './interfaces';
import { map, Observable, catchError, of } from 'rxjs';
import { AppService } from '../../../../app.service';

@Injectable()
export class EmployeeService extends AbstractServerInteractionService {
  protected get url(): {
    search: string,
    verify: string,
    searchByFullSsn: string,
    validateSN: string,
    validatePhone: Function,
    validateAddress: string,
    isActiveEmployee: Function,
    team: Function,
    convertContact: string,
    recruitingLocation: string,
  } {
    return this.appService.getApiUrls().employees;
  }


  constructor(protected appService: AppService,
              protected http: HttpClient) {
    super();
  }

  public getTeam(pageNumber: any): Observable<ITeamResponse | any> {
    return this.http.get(this.url.team(pageNumber, this.appService.getSettings().teamPageSize));
  }

  public getCurrentRecruitingLocation(): Observable<any | IRecruitingLocation> {
    return this.http.get(this.url.recruitingLocation);
  }

  public getSNValidation(data: any): Observable<any> {
    const { ssn, sin, captcha } = data;
    const headers = combineHeadersOptions(globalLoadingDisabled);
    const newHeaders = headers.append('g-recaptcha-response', captcha);

    return this.http.post(
      this.url.validateSN,
      {
        ssn,
        sin
      },
      { headers: newHeaders }
    ).pipe(
      map(({ isValidNumber, employeeId }: any) => ({ isValidNumber, employeeId })));
  }

  public getEmployeeStatus(employeeId: any): Observable<EmployeeStatusEnum> {
    return this.http.get(this.url.isActiveEmployee(employeeId)).pipe(map((isActive: any) => isActive
      ? EmployeeStatusEnum.Active
      : EmployeeStatusEnum.Inactive));
  }

  public convertEmployee(data: IContactConvertationData, captcha: any) {
    const headers = combineHeadersOptions(commonErrorHandlingDisabled);
    const newHeaders = headers.append('g-recaptcha-response', captcha);

    return this.http.post(this.url.convertContact, data, {headers: newHeaders});
  }

  public validateSN(data: any): Observable<any> {
    const { ssn, sin, captcha } = data;
    const headers = combineHeadersOptions(globalLoadingDisabled);
    const newHeaders = headers.append('g-recaptcha-response', captcha);

    return this.http.post(
      this.url.validateSN,
      {
        ssn,
        sin
      },
      {headers: newHeaders}
    ).pipe(
      map(({ isValidNumber, employeeId }: any) => isValidNumber && !employeeId
        ? { valid: true, message: null }
        : { valid: false, message: 'Please enter valid SN' }
      ));
  }

  public searchEmployee(formData: any) {
    const headers = combineHeadersOptions(commonErrorHandlingDisabled);
    return this.http.post(this.url.search, formData, {headers})
      .pipe(catchError((response: any) => of(response.status === 300)));
  }

  public searchEmployeeByFullSsn(formData: any) {
    const headers = combineHeadersOptions(commonErrorHandlingDisabled);
    return this.http.post(this.url.searchByFullSsn, formData, {headers});
  }

  public verifyEmployee(formData: any) {
    const headers = combineHeadersOptions(commonErrorHandlingDisabled);
    return this.http
      .post(this.url.verify, formData,{headers})
      .pipe(map(({ verificationStatus }: any) => verificationStatus));
  }

  public validatePhone(phone: any): any {

    return this.http.get(this.url.validatePhone(phone), {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(map((valid: any): ValidationTypesEnum => valid
        ? ValidationTypesEnum.valid
        : ValidationTypesEnum.invalid));
  }

  public validateAddress(address: any): Observable<any> {
    return this.http.post(this.url.validateAddress, address, {headers: combineHeadersOptions(commonErrorHandlingDisabled)})
      .pipe(map((resp: any) => {
        return {
          valid: resp.isValid,
          city: resp.city,
          state: resp.stateId,
        };
      }));
  }
}

export enum ValidationTypesEnum {
  initial,
  valid,
  invalid
}
