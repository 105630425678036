export interface ISNValidationResponse {
  employeeId: number;
  isValidNumber: boolean;
}

export enum EmployeeStatusEnum {
  None,
  Active,
  Inactive
}

export interface IContactConvertationData {
  ssn: string;
  sin: string;
  birthDate: string;
  agreementId: string;
  isAccountActive: boolean;
  employeeId: number;
}

export interface IRecruitingLocation {
  key: string | null;
  location: string | null;
  otherLocation: boolean | null;
}

interface ITeamMember {
  id: number;
  firstName: string;
  lastName: string;
  experience: string;
  photoUrl: string;
}

export interface ITeamResponse {
  currentPage: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  pageCount: number;
  pageSize: number;
  results: ITeamMember[];
  rowCount: number;
}
